@import url(https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;1,300;1,400;1,600;1,700;1,800&display=swap);
*, *:before, *:after {
  box-sizing: border-box;
}

html, body, div, span, object, iframe, figure, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, code, em, img, small, strike, strong, sub, sup, tt, b, u, i, ol, ul, li, fieldset, form, label, table, caption, tbody, tfoot, thead, tr, th, td, main, canvas, embed, footer, header, nav, section, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -webkit-text-size-adjust: none;
     -moz-text-size-adjust: none;
          text-size-adjust: none;
}

footer, header, nav, section, main {
  display: block;
}

body {
  line-height: 1;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after, q:before, q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

input {
  -webkit-appearance: none;
  border-radius: 0;
}

:root {
  --color-black: #555555;
  --color-orange: #E97D19;
  --color-green: #509B15;
  --color-brown: #A16C40;
  --color-purple: #7E769B;
}

html, body {
  height: 100%;
}

html {
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  color: #555555;
  height: 100%;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100%;
  padding: 0;
  padding: 0;
  margin: 0;
  margin: 0;
}

#root {
  flex-direction: column;
  min-height: 100%;
  display: flex;
}

main {
  flex-direction: row;
  display: flex;
  flex: 1 1;
}
@media screen and (max-width: 960px) {
  main {
    flex-direction: column;
  }
}

.left {
  background: url(/static/media/left-background.7b5078b7.png) no-repeat center center fixed;
  padding: 4rem 10rem 4rem 4rem;
  background-size: cover;
  flex-direction: column;
  display: flex;
  flex-grow: 1;
}
@media screen and (max-width: 960px) {
  .left {
    padding: 2rem;
  }
}

.right {
  background-position: center;
  background-repeat: no-repeat;
  background-color: #dbdbdd;
  background-size: cover;
  position: relative;
  max-width: 400px;
  width: 100%;
}
.right.orange {
  background-image: url(/static/media/right-background-orange.4d8d3fae.jpg);
}
.right.green {
  background-image: url(/static/media/right-background-green.c1bdf312.jpg);
}
.right.brown {
  background-image: url(/static/media/right-background-brown.fa307b75.jpg);
}
.right.purple {
  background-image: url(/static/media/right-background-purple.d7b484fb.jpg);
}
@media screen and (max-width: 960px) {
  .right {
    justify-content: center;
    max-width: inherit;
    display: flex;
    padding: 5rem 2rem;
  }
}
@media screen and (min-width: 960px) {
  .right {
    max-width: 400px;
  }
}
@media screen and (min-width: 1920px) {
  .right {
    max-width: 600px;
  }
}

.content {
  justify-content: center;
  flex-direction: column;
  padding-top: 2rem;
  max-width: 32rem;
  display: flex;
  flex-grow: 1;
}
@media screen and (max-width: 960px) {
  .content {
    margin-top: 4rem;
    max-width: 100%;
  }
}

.contact-us {
  transition: all 0.3s cubic-bezier(0, 0, 0.15, 1);
  box-shadow: 0 0 2rem 0 rgba(0, 0, 0, 0.1), 0 0 4rem 0 rgba(0, 0, 0, 0.1), 0 0 8rem 0 rgba(0, 0, 0, 0.1);
  background-color: rgba(255, 255, 255, 0.9);
  border-radius: 4px;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  position: absolute;
  max-width: 24rem;
  padding: 2rem;
  width: 100%;
  left: -50%;
  top: 50%;
}
.contact-us form {
  flex-direction: column;
  display: flex;
}
.contact-us:hover {
  box-shadow: 0 0 1rem 0 rgba(0, 0, 0, 0.1), 0 0 2rem 0 rgba(0, 0, 0, 0.1), 0 0 4rem 0 rgba(0, 0, 0, 0.1), 0 0 8rem 0 rgba(0, 0, 0, 0.1);
}
@media screen and (min-width: 1920px) {
  .contact-us {
    left: -30%;
  }
}
@media screen and (min-width: 960px) {
  .contact-us {
    left: -30%;
  }
}
@media screen and (max-width: 1280px) {
  .contact-us {
    left: -75%;
    -webkit-transform: translate(50%, -50%);
            transform: translate(50%, -50%);
  }
}
@media screen and (max-width: 960px) {
  .contact-us {
    position: relative;
    -webkit-transform: inherit;
            transform: inherit;
    top: inherit;
    left: inherit;
  }
}

.products-lists {
  justify-content: space-between;
  flex-direction: row;
  margin-bottom: 2rem;
  flex-wrap: wrap;
  display: flex;
}
@media screen and (max-width: 960px) {
  .products-lists {
    flex-direction: column;
    margin-bottom: 5rem;
  }
}

a.logo-link img {
  max-width: 8rem;
}

nav.main-navbar {
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
  display: flex;
}

footer.main-footer {
  letter-spacing: 1;
  color: #a0a0a0;
}

h1.title {
  text-transform: uppercase;
  margin-bottom: 2rem;
  letter-spacing: calc(1rem / 8);
  font-size: 2.5rem;
  font-weight: bold;
}

h2.subtitle1, h2.subtitle2 {
  text-transform: uppercase;
  font-weight: bold;
  font-size: 1rem;
}
h2.subtitle1.orange, h2.subtitle2.orange {
  color: #E97D19;
}
h2.subtitle1.green, h2.subtitle2.green {
  color: #509B15;
}
h2.subtitle1.brown, h2.subtitle2.brown {
  color: #A16C40;
}
h2.subtitle1.purple, h2.subtitle2.purple {
  color: #7E769B;
}

h2.subtitle1 {
  margin-bottom: 1rem;
}

h2.subtitle2 {
  margin-bottom: 1rem;
}

h5.title {
  text-transform: uppercase;
  margin-bottom: 2rem;
  letter-spacing: 2px;
  font-weight: bold;
  font-size: 1.5rem;
  color: #6D6D6D;
}

p.body1 {
  line-height: 1.25rem;
  margin-bottom: 4rem;
  font-size: 1rem;
}

input[type=text].input-label, input[type=email].input-label, textarea.input-label {
  all: unset;
  border-bottom: 1px solid #C5C5C5;
  color: #939393;
  margin-bottom: 2rem;
  padding: 0.5rem 0rem;
}

.input-label {
  text-transform: uppercase;
  font-weight: regular;
  letter-spacing: 2px;
  font-size: 1rem;
}
.input-error {
  margin-bottom: 1rem;
  font-size: 0.85rem;
  margin-top: 0.5rem;
  color: #cc5354;
}

.submit-btn {
  all: unset;
  transition: all 0.3s cubic-bezier(0, 0, 0.15, 1);
  border-radius: 4px;
  text-transform: uppercase;
  justify-content: center;
  background-color: #6D6D6D;
  flex-direction: row;
  align-items: center;
  font-size: 1.125rem;
  letter-spacing: 2px;
  padding: 1rem 2rem;
  font-weight: 500;
  cursor: pointer;
  display: flex;
  color: #ffffff;
}
.submit-btn.orange {
  background: #ff3d00;
  background: linear-gradient(90deg, #ff3d00 0%, #e9af19 100%);
}
.submit-btn.orange:hover {
  box-shadow: 0 0 0.5rem 0 rgba(233, 175, 25, 0.2), 0 0 1rem 0 rgba(233, 175, 25, 0.2), 0 0 2rem 0 rgba(233, 175, 25, 0.2);
}
.submit-btn.green {
  background: #509b15;
  background: linear-gradient(90deg, #509b15 0%, #c9de4d 100%);
}
.submit-btn.green:hover {
  box-shadow: 0 0 0.5rem 0 rgba(201, 222, 77, 0.2), 0 0 1rem 0 rgba(201, 222, 77, 0.2), 0 0 2rem 0 rgba(201, 222, 77, 0.2);
}
.submit-btn.brown {
  background: #531b00;
  background: linear-gradient(90deg, #531b00 0%, #a16c40 100%);
}
.submit-btn.brown:hover {
  box-shadow: 0 0 0.5rem 0 rgba(83, 27, 0, 0.2), 0 0 1rem 0 rgba(83, 27, 0, 0.2), 0 0 2rem 0 rgba(83, 27, 0, 0.2);
}
.submit-btn.purple {
  background: #302432;
  background: linear-gradient(90deg, #302432 0%, #7e769b 100%);
}
.submit-btn.purple:hover {
  box-shadow: 0 0 0.5rem 0 rgba(126, 118, 155, 0.2), 0 0 1rem 0 rgba(126, 118, 155, 0.2), 0 0 2rem 0 rgba(126, 118, 155, 0.2);
}
.submit-btn svg {
  transition: all 0.3s cubic-bezier(0, 0, 0.15, 1);
  margin-left: 0;
}
.submit-btn:hover svg {
  margin-left: 1rem;
}

ul.products-list {
  margin-bottom: 1rem;
}
ul.products-list li {
  line-height: 1.5rem;
  letter-spacing: 3px;
  color: #6D6D6D;
}

